import "./footer.css";

const Footer = () => {

    const showTooltip = () => {
        document.querySelector(".container-tooltip-wpp").classList.toggle("active");
    }

  return (
    <div className="container-footer">
      <div className="container-sections-footer">
        <div>
          <div className="container-logo"></div>
        </div>
        <div className="container-footer-section-servicios">
          <ul>
            <li>
              <span className="title-footer-section">Conócenos</span>
              <ul className="container-tratamientos">
                <li>
                  <a href="">Sobre nosotros</a>
                </li>
                <li>
                  <a href="">Contáctenos</a>
                </li>
                <li>
                  <a href="">Servicios</a>
                </li>
                <li>
                  <a href="">Ubicación</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="container-footer-section-servicios">
          <ul>
            <li>
              <span className="title-footer-section">Nuestros Servicios</span>
              <ul className="container-tratamientos">
                <li>
                  <a href="/Psicologia">Psicología</a>
                </li>
                <li>
                  <a href="/Psicomotricidad">Psicomotricidad</a>
                </li>
                <li>
                  <a href="/Pedagogia">Pedagogía especializada</a>
                </li>
                <li>
                  <a href="/Fonoaudiologia">Fonoaudiología</a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="container-follow-us">
          <div className="container-title-networks">
            <h3>Follow Us</h3>
          </div>
          <div className="container-networks">
            <div className="container-instagram">
              <a href="https://www.instagram.com/centro.arandu" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20 10.9091C17.5889 10.9091 15.2766 11.8669 13.5717 13.5718C11.8668 15.2767 10.9091 17.589 10.9091 20C10.9091 22.4111 11.8668 24.7234 13.5717 26.4283C15.2766 28.1331 17.5889 29.0909 20 29.0909C22.411 29.0909 24.7233 28.1331 26.4282 26.4283C28.1331 24.7234 29.0909 22.4111 29.0909 20C29.0909 17.589 28.1331 15.2767 26.4282 13.5718C24.7233 11.8669 22.411 10.9091 20 10.9091ZM14.5454 20C14.5454 21.4467 15.1201 22.834 16.143 23.857C17.1659 24.8799 18.5533 25.4546 20 25.4546C21.4466 25.4546 22.834 24.8799 23.8569 23.857C24.8798 22.834 25.4545 21.4467 25.4545 20C25.4545 18.5534 24.8798 17.166 23.8569 16.1431C22.834 15.1202 21.4466 14.5455 20 14.5455C18.5533 14.5455 17.1659 15.1202 16.143 16.1431C15.1201 17.166 14.5454 18.5534 14.5454 20Z"
                    fill="black"
                  />
                  <path
                    d="M30.9091 7.27271C30.4269 7.27271 29.9644 7.46426 29.6235 7.80524C29.2825 8.14621 29.0909 8.60868 29.0909 9.09089C29.0909 9.5731 29.2825 10.0356 29.6235 10.3765C29.9644 10.7175 30.4269 10.9091 30.9091 10.9091C31.3913 10.9091 31.8538 10.7175 32.1948 10.3765C32.5357 10.0356 32.7273 9.5731 32.7273 9.09089C32.7273 8.60868 32.5357 8.14621 32.1948 7.80524C31.8538 7.46426 31.3913 7.27271 30.9091 7.27271Z"
                    fill="black"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.27273 0C5.34388 0 3.49403 0.766232 2.13013 2.13013C0.766232 3.49403 0 5.34388 0 7.27273V32.7273C0 34.6561 0.766232 36.506 2.13013 37.8699C3.49403 39.2338 5.34388 40 7.27273 40H32.7273C34.6561 40 36.506 39.2338 37.8699 37.8699C39.2338 36.506 40 34.6561 40 32.7273V7.27273C40 5.34388 39.2338 3.49403 37.8699 2.13013C36.506 0.766232 34.6561 0 32.7273 0H7.27273ZM32.7273 3.63636H7.27273C6.3083 3.63636 5.38338 4.01948 4.70143 4.70143C4.01948 5.38338 3.63636 6.3083 3.63636 7.27273V32.7273C3.63636 33.6917 4.01948 34.6166 4.70143 35.2986C5.38338 35.9805 6.3083 36.3636 7.27273 36.3636H32.7273C33.6917 36.3636 34.6166 35.9805 35.2986 35.2986C35.9805 34.6166 36.3636 33.6917 36.3636 32.7273V7.27273C36.3636 6.3083 35.9805 5.38338 35.2986 4.70143C34.6166 4.01948 33.6917 3.63636 32.7273 3.63636Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
            <div className="container-wpp" onClick={showTooltip}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 44 44"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M37.376 6.35752C35.3536 4.33415 32.9509 2.73078 30.3064 1.6397C27.6618 0.548627 24.8276 -0.00860002 21.9668 0.000100332C9.96094 0.000100332 0.1875 9.72471 0.182617 21.6798C0.177306 25.4865 1.18076 29.2267 3.09082 32.5196L0 43.7501L11.5479 40.7355C14.7444 42.4671 18.3226 43.3734 21.958 43.3722H21.9668C33.9717 43.3722 43.7441 33.6466 43.75 21.6925C43.7573 18.8415 43.1975 16.0175 42.1032 13.3848C41.0089 10.7521 39.402 8.36338 37.376 6.35752ZM21.9668 39.713H21.959C18.7181 39.7139 15.5362 38.8466 12.7441 37.2013L12.083 36.8106L5.23047 38.5997L7.05957 31.9503L6.62891 31.2667C4.81678 28.3977 3.85669 25.0731 3.86035 21.6798C3.86035 11.7452 11.9863 3.66221 21.9736 3.66221C26.7646 3.65365 31.3629 5.54847 34.7569 8.92991C38.151 12.3113 40.0629 16.9025 40.0723 21.6935C40.0684 31.629 31.9463 39.713 21.9668 39.713ZM31.8975 26.2179C31.3535 25.9464 28.6748 24.6358 28.1787 24.4552C27.6826 24.2745 27.3164 24.1837 26.9541 24.7267C26.5918 25.2696 25.5479 26.4845 25.2305 26.8507C24.9131 27.2169 24.5957 27.2569 24.0518 26.9855C23.5078 26.714 21.7529 26.1427 19.6738 24.297C18.0557 22.8605 16.9639 21.087 16.6465 20.545C16.3291 20.003 16.6123 19.7091 16.8848 19.4396C17.1299 19.1964 17.4287 18.8067 17.7012 18.4903C17.9736 18.1739 18.0645 17.9474 18.2451 17.586C18.4258 17.2247 18.3359 16.9083 18.2002 16.6378C18.0645 16.3673 16.9756 13.7003 16.5225 12.6153C16.0801 11.5587 15.6318 11.7022 15.2979 11.6856C14.9805 11.67 14.6143 11.6661 14.2539 11.6661C13.9784 11.6733 13.7074 11.7372 13.4577 11.8538C13.208 11.9704 12.9851 12.1372 12.8027 12.3438C12.3037 12.8868 10.8975 14.1993 10.8975 16.8634C10.8975 19.5274 12.8506 22.1056 13.1201 22.4669C13.3896 22.8282 16.958 28.2999 22.418 30.6466C23.4318 31.0808 24.4678 31.4615 25.5215 31.7872C26.8252 32.1993 28.0117 32.1417 28.9492 32.0021C29.9951 31.8468 32.1719 30.6915 32.624 29.4259C33.0762 28.1603 33.0771 27.0763 32.9414 26.8507C32.8057 26.6251 32.4424 26.4884 31.8975 26.2179Z"
                      fill="black"
                    />
                  </svg>
              <div className="container-tooltip-wpp">
                <a href="https://wa.me/+59895493829" target="_blank" rel="noopener noreferrer"><b>095493829</b> - Psicología</a>
                <a href="https://wa.me/+59892409173" target="_blank" rel="noopener noreferrer"><b>092409173</b> - Pedagogía</a>
                <a href="https://wa.me/+59899095919" target="_blank" rel="noopener noreferrer"><b>099095919</b> - Psicomotricidad</a>
                <a href="https://wa.me/+59892365968" target="_blank" rel="noopener noreferrer"><b>092365968</b> - Fonoaudiología</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-copyright">
        <ul className="copyright-list">
          <li>
            <a href="">Políticas de privacidad</a>
          </li>
          <li>© 2023, Arandú</li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
