import "./fonoaudiologia.css"

const Fonoaudiologia = () => {
    return(
        <div className="container-fonoaudiologia">
            <div className="section-fonoaudiologia">
                <h1>FONOAUDIOLOGÍA</h1>
                <div className="container-img-description-fonoaudiologia">
                    <img src="../assets/fonoaudiologia.jpg" alt="" />
                    <div className="container-fonoaudiologia-description">
                        <h2>Descripción</h2>
                        <ul>
                            <li>En la infancia, el desarrollo del lenguaje juega un papel fundamental en el crecimiento y la comunicación de los niños/as. Durante esta etapa, los niños/as adquieren habilidades lingüísticas que les permiten expresarse, comprender el mundo que los rodea y establecer relaciones sociales. El desarrollo del lenguaje abarca desde la adquisición de sonidos y palabras hasta la capacidad de formar oraciones y mantener una conversación fluida.</li>
                            <li>Los profesionales en fonoaudiología ayudan a identificar y tratar los posibles problemas de habla, lenguaje y audición que pueden afectar el desarrollo lingüístico de los niños/as. Además, se enfoca en desarrollar habilidades de comunicación efectivas, mejorar la pronunciación, la comprensión del lenguaje y la capacidad de expresarse correctamente.</li>
                            <li>El tratamiento en el área de fonoaudiología se centra en objetivos específicos para mejorar la pronunciación, la comprensión del lenguaje y la capacidad de expresarse adecuadamente, según las necesidades específicas de cada consultante. </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
} 

export default Fonoaudiologia