import "./psicologia.css"

const Psicologia = () => {
    return(
        <div className="container-psicologia">
            <div className="section-psicologia">
                <h1>ATENCIÓN PSICOLÓGICA</h1>
                <div className="container-img-description-psicologia">
                    <img src="../assets/img-slider1.jpg" alt="" />
                    <div className="container-psicologia-description">
                        <h2>Descripción</h2>
                        <ul>
                            <li>La psicología clínica en el ámbito infantil y adolescente trabaja de formas diversas, considerando siempre la singularidad e historia específica de quien llega a la consulta.</li>
                            <li>Pensamos el espacio psicoterapéutico como aquel lugar donde se intenta recorrer junto al paciente un camino que lo habilite a desplegar su mundo interno, sus deseos, temores y fantasías.</li>
                            <li>Los niños y niñas expresan lo que sienten principalmente a través del juego, utilizando recursos imaginativos y simbólicos. El trabajo en el proceso psicoterapéutico se realiza utilizando este medio, de modo que el juego, los dibujos, así como factores creativos se toman como herramientas fundamentales para el trabajo con infancias.</li>
                            <li>A partir de aquí, se ira realizando un proceso junto al niño/adolocente, el cual le permita poco a poco ir pensando y resignificando su conflictiva.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Psicologia