import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import "./header.css";

const Header = () => {
  const navigate = useNavigate();

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navigateAndScroll = (id) => {
    if (window.location.pathname !== '/') {
      navigate('/', { state: { scrollTo: id } });
      setTimeout(() => {
        handleScroll(id);
      });

    } else {
      handleScroll(id);
    }
  };

  const setMenuStatus = () => {
    document
      .querySelector(".container-hamburger-group")
      .classList.toggle("active");
    document
      .querySelector(".header-group")
      .classList.toggle("active");
  };
  return (
    <>
      <header className="header-scroll">
        <nav className="container-nav">
          <div className="contenedor-logos">
            <div className="container-logo-header-arbol"></div>
            <div className="container-logo-header"></div>
            <div className="container-hamburguer-header" onClick={setMenuStatus}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  d="M4.5 25.5H31.5M4.5 18H31.5M4.5 10.5H31.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
          <ul className="header-group">
            <li>
              <a className="header-button" href="/">
                Home
              </a>
            </li>
            <li onClick={() => navigateAndScroll('nosotros')}>
              <a className="header-button">Nosotros</a>
            </li>
            <li onClick={() => navigateAndScroll('servicios')}>
              <a className="header-button">Servicios</a>
            </li>
            <li onClick={() => navigateAndScroll('convenios')}>
              <a className="header-button">Convenios</a>
            </li>
            <li onClick={() => navigateAndScroll('contacto')}>
              <a className="header-button">Contacto</a>
            </li>
          </ul>
        </nav>
        <div className="container-hamburger-menu">
          <div className="container-hamburger-group">
            <ul className="hamburger-header-group">
              <li>
                <a href="/">Home</a>
              </li>
              <li onClick={() => navigateAndScroll('contacto')}>
                <a>Contacto</a>
              </li>
              <li onClick={() => navigateAndScroll('nosotros')}>
                <a>Nosotros</a>
              </li>
              <li onClick={() => navigateAndScroll('servicios')}>
                <a>Servicios</a>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;