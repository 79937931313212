import "./contacto.css";

const Contacto = () => {
  const onFocusField = (e) => {
    if (
      e.currentTarget.lastChild.className == "contact-field" &&
      e.currentTarget.lastChild.value == ""
    ) {
      e.target.parentElement.firstChild.classList.add("focusInput");
    }
    if (
      e.currentTarget.lastChild.className == "contact-textArea-field" &&
      e.currentTarget.lastChild.value == ""
    ) {
      e.target.parentElement.firstChild.classList.add("focusTextArea");
    }
  };

  const onBlurField = (e) => {
    if (
      e.currentTarget.lastChild.className == "contact-field" &&
      e.currentTarget.lastChild.value == ""
    ) {
      e.target.parentElement.firstChild.classList.remove("focusInput");
    }
    if (
      e.currentTarget.lastChild.className == "contact-textArea-field" &&
      e.currentTarget.lastChild.value == ""
    ) {
      e.target.parentElement.firstChild.classList.remove("focusTextArea");
    }
  };

  return (
    <div className="container-contact-section" id="contacto">
      <h1 className="global-title">Contáctenos</h1>
      <div className="container-map-contact">
        <div className="container-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1639.4142701138371!2d-56.03183982652028!3d-34.73471603436506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a02f539203fdf9%3A0x5d384cfc8a6bf863!2sZorrilla%20de%20San%20Mart%C3%ADn%2041%2C%2015500%20Joaqu%C3%ADn%20Su%C3%A1rez%2C%20Departamento%20de%20Canelones!5e0!3m2!1ses!2suy!4v1696181682449!5m2!1ses!2suy"
            width="600"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="container-contact" id="contact-form">
          <h2>Formulario de contácto</h2>
          <div className="contact-form">
            <div onBlur={onBlurField} onFocus={onFocusField}>
              <p>Nombre</p>
              <input className="contact-field" type="text" />
            </div>
            <div onBlur={onBlurField} onFocus={onFocusField}>
              <p>Apellido</p>
              <input className="contact-field" type="text" />
            </div>
            <div onBlur={onBlurField} onFocus={onFocusField}>
              <p>Contacto</p>
              <input className="contact-field" type="number" />
            </div>
            <div
              className="container-input-mail"
              onBlur={onBlurField}
              onFocus={onFocusField}
            >
              <p>Mail</p>
              <input className="contact-field" type="mail" />
            </div>
            <div
              className="text-area-form"
              onBlur={onBlurField}
              onFocus={onFocusField}
            >
              <p>Escribe un mensaje...</p>
              <textarea
                className="contact-textArea-field"
                name=""
                id=""
                cols="100"
                rows="3"
              ></textarea>
            </div>
          </div>
          <button className="button-form">Enviar</button>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
