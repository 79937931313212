import "./servicios.css";
import Silder from "react-slick";
import trabajadores from "../trabajadores-data";

const Servicios = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="container-slider-trabajadores">
      <div className="container-slider-section">
        <Silder {...settings}>
          {trabajadores.map((trabajador) => {
            return (
              <div>
                <div className="card-trabajadores">
                  <h3>{trabajador.name}</h3>
                  <p>{trabajador.titulo}</p>
                </div>
              </div>
            );
          })}
        </Silder>
      </div> 
    </div>
  );
};
export default Servicios;
