import "./pedagogia.css"

const Pedagogia = () => {
    return(
        <div className="container-pedagogia">
            <div className="section-pedagogia">
                <h1>ATENCIÓN PEDAGÓGICA</h1>
                <div className="container-img-description-pedagogia">
                    <img src="../assets/pedadogia.jpg" alt="" />
                    <div className="container-pedagogia-description">
                        <h2>Descripción</h2>
                        <ul>
                            <li>El proceso de aprendizaje es una actividad individual que se desarrolla en un contexto social y cultural determinado. El aprendizaje adquirido al momento de ingresar a las instituciones educativas, serán las herramientas con las que contará el niño/a para transitar una exitosa inserción escolar y aprendizaje académico. Este proceso es complejo y puede verse interferido por múltiples variables que desencadenan en una situación problemática que requiera una consulta especializada.</li>
                            <li>Las Dificultades de Aprendizaje pueden presentarse en cualquier individuo incluyendo a aquellos con inteligencia media o media alta y no están vinculadas a un impedimento visual, auditivo o motor, ni discapacidad intelectual o disturbios emocionales aunque pueda ocurrir en alguno de ellos.</li>
                            <li>Las dificultades de aprendizaje están vinculadas a un grupo de trastornos debido a dificultades identificables del sistema nervioso central en alguna de las siguientes áreas: atención, memoria, razonamiento, coordinación, lectura, escritura, ortografía, competencia social y maduración emocional.</li>
                            <li>La intervención pedagógica especializada o psicopedagógica incluye el proceso de diagnóstico y abordaje de las dificultades de aprendizaje que suelen reflejarse en rendimientos académicos descendidos.</li>
                            <li>El abordaje incluye el trabajo con psicopedagógas o Maestras Especializadas.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
 
export default Pedagogia