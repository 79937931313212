import "./section.css";
import sectionInformation from "../section-information";
import { Link } from "react-router-dom";

const SectionGrid = () => {
  return (
    <div className="container-section-grid" id="servicios">
      <div className="container-section">
        <h2 className="global-title">Áreas de atención</h2>
        <div className="container-card-section-grid">
         { sectionInformation.map((elemento) => {
            return(
                <div className="card-section-grid">
                    <img src={elemento.img} alt="" />
                    <h2>{elemento.titulo}</h2>
                   <Link to={elemento.url}><button>Saber más</button></Link> 
                </div>
            )
         })}
        </div>
      </div>
    </div>
  );
};

export default SectionGrid;
