import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Banner from './Banner/banner';
import Footer from './Footer/footer';
import Header from './Header/header';
import Psicologia from './Psicologia/psicologia';
import Pedagogia from './Pedagogia/pedagogia';
import Psicomotricidad from './Psicomotricidad/psicomotricidad';
import Fonoaudiologia from './Fonoaudiologia/fonoaudiologia';
import ScrollToTop from './scrolltop';


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Header/>
      <Routes>
        <Route path='/' element={<Banner/>}/>
        <Route path='/Psicologia' element={<Psicologia/>} />
        <Route path='/Pedagogia' element={<Pedagogia/>} />
        <Route path='/Psicomotricidad' element={<Psicomotricidad/>} />
        <Route path='/Fonoaudiologia' element={<Fonoaudiologia/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
